import React from "react";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@layout/DefaultPageContainer"));

const PageHero = loadable(() => import("@organisms/PageHero"));
const EventContent = loadable(() =>
  import("../components/templates/EventContent")
);

const HomePage = ({ data, ...rest }) => {
  const { hero, blocks, form } = data;

  return (
    <PageContainer {...rest} flex={false}>
      <PageHero small {...hero} />
      <EventContent blocks={blocks} hero={hero} form={form} />
    </PageContainer>
  );
};

HomePage.defaultProps = {};

export default HomePage;
